import { LayoutDefault } from "components/layouts/LayoutDefault"
import { SEO } from "components/SEO"
import { graphql, PageProps } from "gatsby"
import React, { Suspense } from "react"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from "styled-components/cssprop"

// import { ContentfulComponentsSwitch } from "src/components/composite/ContentfulComponentsSwitch"
import { Footer } from "src/components/composite/Footer"
import { Header } from "src/components/composite/Header"
import { Spinner } from "src/components/ui/Spinner"
import { PageQuery } from "src/generated/graphql"
import { isSSR } from "src/utils/isSSR"

interface Props extends PageProps {
  data: PageQuery
}

const ContentfulComponentsSwitch = React.lazy(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new Promise<any>((resolve) => {
    setTimeout(
      () =>
        resolve(import("src/components/composite/ContentfulComponentsSwitch")),
      500
    )
  })
})

const PageTemplate: React.FC<Props> = ({ data, location, navigate }) => {
  if (!data || !data.contentfulPage) {
    return null
  }

  const {
    id,
    pageTitle,
    pageDescription,
    floatingHeader,
    components,
    hideFooter,
  } = data.contentfulPage

  return (
    <LayoutDefault>
      <SEO title={pageTitle || ""} description={pageDescription || ""} />
      <Header floating={Boolean(floatingHeader)} />
      {!isSSR && (
        <Suspense fallback={<Spinner loadingDelay={0} />}>
          <ContentfulComponentsSwitch pageId={id} components={components} />
        </Suspense>
      )}
      <Footer isHiddenOnDesktop={hideFooter} />
    </LayoutDefault>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query Page($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      slug
      pageTitle
      pageDescription
      floatingHeader
      hideFooter
      ...ContentfulPageComponents
    }
  }
`
