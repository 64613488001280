import React from "react"
import styled from "styled-components"

import { theme } from "src/styles/theme"

interface SpinnerProps {
  loadingDelay?: number
}

export const Spinner: React.FC<SpinnerProps> = ({ loadingDelay = 500 }) => {
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const spinnerTimeout = React.useRef<NodeJS.Timeout>()

  React.useEffect(() => {
    spinnerTimeout.current = setTimeout(() => {
      setShowSpinner(true)
    }, loadingDelay)

    return () => {
      if (
        spinnerTimeout.current !== null &&
        spinnerTimeout.current !== undefined
      ) {
        clearTimeout(spinnerTimeout.current)
      }
    }
  }, [loadingDelay])

  return showSpinner ? (
    <Wrapper>
      <div className="loader">Loading...</div>
    </Wrapper>
  ) : null
}

const backgroundColor = theme.color.primary
const spinnerColor = "#fff"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: ${backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;

  .loader,
  .loader:before,
  .loader:after {
    background: ${spinnerColor};
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  .loader {
    color: ${spinnerColor};
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    position: absolute;
    top: 0;
    content: "";
  }
  .loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 1.5em;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
`
